//= require react-15.1.0.min
//= require react-dom-15.1.0.min
//= require_self
//= require cart

import smoothscroll from 'smoothscroll-polyfill';
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
if (!reducedMotion.matches) {
  smoothscroll.polyfill();
}

var buyButton = document.querySelectorAll(".jump-to-purchase")[0];
if (buyButton) {
  var purchaseForm = document.getElementById("purchase");
  buyButton.addEventListener('click', function(evt) {
    purchaseForm.scrollIntoView({ behavior: 'smooth' });
  });
}

import './cart.js.jsx';

// Type tester code
// This doesn’t use React, but it’s frankly simpler to just
// manipulate the DOM here.
function toggleFont(font, element) {
  // This is ugly: looking up across the parents to find the container div
  // Even a clean fix requires stepping up to find the right name
  var parent = element.parentNode.parentNode.parentNode.parentNode.parentNode;
  // There’s only one of these, so we just take from the top of the stack
  var samples = parent.querySelectorAll('.ttc-tester-samples')[0];
  // Change the font value on all the samples at once - makes most sense
  samples.setAttribute('class', 'ttc-tester-samples ' + font);
  event.preventDefault();
}

function toggleSize(size, element) {
  // As before, ugly but at least it’s honest
  var parent = element.parentNode.parentNode.parentNode.parentNode.parentNode;
  // Get all the divs that are the size samples
  var samples = parent.querySelectorAll('.ttc-tester-samples div');
  // Loop through them
  for (var ch = 0; ch < samples.length; ch++) {
    var object = samples[ch];
    // And if it’s a match with the name we want, display it
    if (object.classList.contains(size)) {
      object.setAttribute("style", "display: block;");
    } else { // Otherwise, hide it
      object.setAttribute("style", "display: none;");
    }
  }
  event.preventDefault();
}

function toggleFeature(feature, element) {
  var parent = element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
  var samples = parent.querySelectorAll('.ttc-tester-samples > div');

  var featurechx = element.parentNode.parentNode.parentNode.querySelectorAll('[type="checkbox"]');
  var features = {};
  for (var i = 0; i < featurechx.length; i++) {
    var value = featurechx[i].getAttribute('data-featurechoice').toLowerCase().replace(/\s+/g, "-");
    var value_name = featurechx[i].getAttribute('data-featurechoice');
    if (featurechx[i].checked == true) {
      var clean = 'fea--' + value;
      features[value] = true;
      for (var s = 0; s < samples.length; s++) {
        samples[s].classList.add(clean);
      }
    } else if (featurechx[i].checked == false) {
      var clean = 'fea--' + value;
      features[value] = false;
      for (var s = 0; s < samples.length; s++) {
        samples[s].classList.remove(clean);
      }
    }
  }
  event.preventDefault();
}

// Stolen from https://stackoverflow.com/questions/2176861/javascript-get-clipboard-data-on-paste-event-cross-browser/6804718#6804718
function handlePaste (e) {
  var clipboardData, pastedData;

  // Stop data actually being pasted into div
  e.stopPropagation();
  e.preventDefault();

  // Get pasted data via clipboard API
  clipboardData = e.clipboardData || window.clipboardData;
  pastedData = clipboardData.getData('Text');

  // Do whatever with pasteddata
  document.execCommand('insertHTML', false, pastedData);
}

function sampleEditor(object) {
  var sample = object;
  var sampleGroup = object.parentNode.parentNode.parentNode;
  sampleGroup.classList.add('js');

  var targetFont = sample.parentNode.getAttribute('class');
  var targetSize = sample.classList.item(0);

  var fontSize = Math.round(getComputedStyle(object).getPropertyValue('font-size').slice(0,-2));
  var slider = '<input id="js-fontsize" name="js-fontsize" attr-target="' + targetFont + '" attr-scale="' + targetSize + '" type="range" min="16" max="144" value="' + fontSize + '" oninput="window.sampleScaler(this)" onchange="window.sampleScaler(this)" /> <span class="scale-label">' + fontSize + 'px</span>';
  var sliderWrapper = '<label for="js-fontsize">' + slider + '</label>';
  var sizeInput = document.createElement('DIV');
  sizeInput.innerHTML = sliderWrapper;
  sizeInput.classList.add('ttc-tester-size', 'ttc-tester-ui');
  sample.setAttribute('contenteditable', 'true');
  sample.addEventListener('paste', handlePaste);
  sample.classList.add('editable');
  sample.parentNode.insertBefore(sizeInput, sample.parentNode.childNodes[0]);
}

function sampleScaler(ref) {
  var parentTarget = ref.getAttribute('attr-target');
  var parentScale = ref.getAttribute('attr-scale');
  var textSample = document.body.querySelector('.' + parentTarget + ' > .' + parentScale);
  // var scaleLabel = textSample.parentNode.querySelector('span.scale-label');
  var scaleLabel = ref.parentNode.querySelector('span.scale-label');
  textSample.setAttribute('style', 'font-size: ' + ref.value + 'px');
  scaleLabel.innerHTML = ref.value + 'px';
}
window.sampleScaler = sampleScaler


function typeTester(object) {
  var parent = object;
  var text = parent.childNodes[0];
  // Store font options as a data-fonts attribute, comma+space-separated
  var fonts = parent.getAttribute('data-fonts').split(', ');
  var fontOptions = '';
  // For each font in the list, get the clean name and create a CSS class name of it
  // Then, append a simple link to the fontOptions
  var fontPrefix = 'font__';
  for (var fi = 0; fi < fonts.length; fi++) {
    var fontStyleName = fonts[fi].toLowerCase().replace(/\s+/g, "-");
    fontStyleName = fontPrefix + fontStyleName;
    fontOptions += '<li><a onclick="toggleFont(\'' + fontStyleName + '\', this);" class="' + fontStyleName + '" data-fontchoice="' + fontStyleName + '">' + fonts[fi] + '</a></li>';
  }
  // On load, get the samples div and apply the font class for the first font in the list
  var samples = parent.querySelectorAll('.ttc-tester-samples')[0];
  samples.setAttribute('class', 'ttc-tester-samples ' + fontPrefix + fonts[0].toLowerCase().replace(/\s+/g, "-"));

  // Different font size options
  var sizes = {
    'Paragraph': 'ttc-tester--paragraph',
    'Headline': 'ttc-tester--headline',
    'Big': 'ttc-tester--big'
  }
  var sizeOptions = '';
  // For each font size options, make a simple link with the name as a label and the value as an attr
  for (var label in sizes) {
    if (sizes.hasOwnProperty(label)) {
      sizeOptions += '<li><a onclick="toggleSize(\'' + sizes[label] + '\', this);" data-sizechoice="' + sizes[label] + '">' + label + '</a></li>';
    }
  }

  var features = parent.getAttribute('data-features').split(', ');
  var featureOptions = '';
  if (features) {
    for (var f = 0; f < features.length; f++) {
      var fname = features[f].toLowerCase().replace(/\s+/g, "-");
      var fname_pretty = features[f];
      featureOptions += '<li><label for="fea--' + fname + '"><input type="checkbox" id="fea--' + fname + '" onChange="toggleFeature(\'' + fname + '\', this);" data-featurechoice="' + fname + '" />' + fname_pretty + '</label></li>';
    }
  }

  // Create a few elements and load in the generated HTML
  var controls = document.createElement('DIV');
  controls.classList.add('js-ttc-tester-controls');
  var sizePicker = document.createElement('DIV');
  sizePicker.classList.add('js-ttc-tester-size-select');
  sizePicker.innerHTML = '<a class="js-ttc-tester-label">Size</a><ul>' + sizeOptions + '</ul>';
  var fontPicker = document.createElement('DIV');
  fontPicker.classList.add('js-ttc-tester-font-select');
  fontPicker.innerHTML = '<a class="js-ttc-tester-label">Style</a><ul>' + fontOptions + '</ul>';
  if (features) {
    var featurePicker = document.createElement('DIV');
    featurePicker.classList.add('js-ttc-tester-feature-select');
    featurePicker.innerHTML = '<a class="js-ttc-tester-label">OpenType</a><ul>' + featureOptions + '</ul>';
  }
  // Then, append the new HTML to the parent tester
  controls.appendChild(fontPicker);
  controls.appendChild(sizePicker);
  if (features) { controls.appendChild(featurePicker); }
  parent.appendChild(controls);

  parent.setAttribute("style", "margin: 2rem auto;");
}


var mainNav = document.getElementById('main-nav');
var navToggle = document.getElementById('nav-toggle-button');
var persistentCart = document.getElementById('js-persistent-cart');

window.addEventListener('load', function() {
  function toggleNavClass(evt) {
    document.body.classList.toggle('nav-open');
    evt.preventDefault();
  }
  navToggle.addEventListener('click', toggleNavClass, true);

  if (document.getElementById('styles')) {
    var typeTesterObjects = document.getElementsByClassName('js-ttc-tester');
    var sampleObjects = document.getElementById('styles').getElementsByClassName('tester-phrase');
  
    for (var i = 0; i < sampleObjects.length; i++) {
      var element = sampleObjects[i];
      sampleEditor(element);
    }
  
    // Check for testers across the page, and load in the markup and changer magic when found
    for (var i = 0; i < typeTesterObjects.length; i++) {
      var element = typeTesterObjects[i];
      typeTester(element);
    }
  }

})


if(window.attachEvent) {
  window.attachEvent('onresize', function() {
    if ( window.screen.width >= 720 ) { // Medium size at which point burger nav appears
      document.body.classList.remove('nav-open');
    }
  });
}
else if(window.addEventListener) {
  window.addEventListener('resize', function() {
    if ( window.screen.width >= 720 ) { // Medium size at which point burger nav appears
      document.body.classList.remove('nav-open');
    }
  }, true);
}
else { // The browser does not support Javascript event binding
}

// Detect user touch and make a class to modify some interaction elements
window.addEventListener('touchstart', function onFirstTouch() {
  document.body.classList.add('user-touch');
  window.USER_TOUCH = true;
  window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

// Persistent/Sticky cart indicator
function togglePersistentCart (persistentCart) {
  var scrollVal = window.pageYOffset;
  if (persistentCart) {
    if (scrollVal > mainNav.clientHeight) {
      persistentCart.classList.add('visible')
    } else {
      persistentCart.classList.remove('visible')
    }
  }
}
window.addEventListener('scroll', function () {
  if (!persistentCart) {
    persistentCart = document.getElementById('js-persistent-cart');
  }
  togglePersistentCart(persistentCart);
});
togglePersistentCart(persistentCart);