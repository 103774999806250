import React from 'react';
import ReactDOM from 'react-dom';

const TTC = {}

TTC._hooks = []
TTC.onChange = function(fn) {
  TTC._hooks.push(fn)
}

TTC.didChange = function() {
  // Trigger that something changed
  for (var i = 0; i < TTC._hooks.length; i++) {
    TTC._hooks[i].call();
  }
};

TTC.render = function(element, id) {
  var container = document.getElementById(id);
  if (!container)
    return;

  var component = ReactDOM.render(element, container);
  TTC.onChange(function() {
    component.forceUpdate();
  });
  return component;
};

function getAsync(uri, callback) {
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open('GET', uri, true);
  xmlHttp.onreadystatechange = function() {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
      callback(xmlHttp.response);
  };
  xmlHttp.send(null);
}

getAsync('/scripts/releases.json', function(response) {
  TTC.fontset = JSON.parse(response);
  TTC.fontbysku = {};
  for (var i = 0; i < TTC.fontset.length; i++) {
    var font = TTC.fontset[i];
    font.fullPrice = font.price * font.styles.length;
    TTC.fontbysku[font.sku] = font;
    for (var j = 0; j < font.styles.length; j++) {
      var style = font.styles[j];
      style.fullPrice = font.price;
      TTC.fontbysku[style.sku] = style;
    }
  }
  setupCart();
});

getAsync('/scripts/licenses.json', function(response) {
  TTC.licenses = JSON.parse(response);
  setupCart();
});

function setupCart() {
  if (!TTC.licenses) return;
  if (!TTC.fontset) return;

  // (1) Setup the cart
  TTC.cart = readCart();
  TTC.onChange(function() {
    // Whenever something changes, we persist the cart.
    // Better do this too often than not enough.
    writeCart(TTC.cart);
  });

  // (2) Render cart in the menu
  TTC.render(<Cart />, 'js-shopping-cart');

  // (3) Hook up the purchase form
  var purchase = document.getElementById('js-purchase');
  if (purchase) {
    setupPurchase(purchase);
  }
}

// (1) Cart management
function readCart() {
  try {
    return JSON.parse(localStorage.cart);
  } catch (err) {
    return [];
  }
}

function writeCart(cart) {
  localStorage.cart = JSON.stringify(cart);
}

var Cart = React.createClass({
  getInitialState: function() {
    return {
      showCart: false
    };
  },

  render: function() {
    var counter = '';
    var persistent = '';
    {if (TTC.cart.length >= 1)
      counter = (<span className="cart-item-count">{TTC.cart.length}</span>);
    }
    {if (TTC.cart.length >= 1 && counter != '')
      persistent = (<div id="js-persistent-cart" className="shopping-cart-persistent"><a href="#showcart" onClick={this.toggleCart}>Cart {counter}</a></div>);
    }

    return <div>
      {persistent}
      <div className="shopping-cart-link">
        <a href="#showcart" onClick={this.toggleCart}>Cart {counter}</a>
      </div>
      {this.state.showCart && this.renderCart() }
    </div>;
  },

  renderCart: function() {
    if (TTC.cart.length == 0) {
      return <div className="shopping-cart empty-cart" onClick={this.toggleCart}>
        <a href="#hidecart" className="js-close-button">Close</a>
        <h2>Your cart is empty</h2>
        <p>Make a selection of typefaces and come back here when you’re ready.</p>
      </div>;
    }

    function extract(line) {
      var font = TTC.fontbysku[line.sku];
      return {
        name: font.name,
        price: font.fullPrice * line.license,
        license: line.license,
        onChangeLicense: function(value) {
          line.license = value;
          TTC.didChange();
        }
      };
    }

    function getTotalPrice () {
      const prices = []
      TTC.cart.forEach((itm) => {
        prices.push(TTC.fontbysku[itm.sku].fullPrice * itm.license)
      })
      return prices.reduce((a, b) => a + b, 0)
    }

    return <div className="shopping-cart">
      <a href="#hidecart" className="js-close-button" onClick={this.toggleCart}>Close</a>
      <h3>In your cart</h3>
      <table className="cart-table">
        <thead>
          <tr>
            <th className="thead-style">Style</th>
            <th>License</th>
            <th className="thead-price">Price</th>
          </tr>
        </thead>
        <tbody>
          {TTC.cart.map(function(line, idx) {
            return <ProductLine key={idx} onRemove={this.removeLine.bind(this, idx)} {...extract(line)} />;
          }, this)}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={2}>Total</th>
            <td>€ { getTotalPrice() }</td>
          </tr>
        </tfoot>
      </table>
      <a className="button js-cart-copy_purchase_link alert-in" onClick={this.copyLink} title="Copy the URL to this order, for easy sharing">Share cart link</a>
      <button className="primary" onClick={this.checkout}>Proceed to checkout</button>
    </div>
  },

  toggleCart: function(evt) {
    evt.preventDefault();
    this.state.showCart = !this.state.showCart;
    this.forceUpdate();
  },

  copyLink: function(evt) {
    var url = 'https://order.tinytype.co/pay?';
    TTC.cart.forEach(function(line) {
      url += 'product:' + line.sku + '[]=' + line.license + '&';
    });

    var c = document.querySelector('.js-cart-copy_purchase_link').classList;

    c.remove('alert-in');
    c.add('alert-out');
    setTimeout(function() {
      c.add('alert-in');
      c.remove('alert-out');
    }, 3000);

    navigator.clipboard.writeText(url);
  },

  removeLine: function(idx) {
    TTC.cart.splice(idx, 1);
    TTC.didChange();
  },

  checkout: function(evt) {
    var url = 'https://order.tinytype.co/pay?';
    TTC.cart.forEach(function(line) {
      url += 'product:' + line.sku + '[]=' + line.license + '&';
    });

    // TODO: Now it clears when you go to checkout, but:
    // Should it? Should it perhaps clear when you’ve gone through at least the
    // step of making an order? Right now, if you’re trigger-happy,
    // you can lose your selection. Not sure how this would be doable for
    // v1.0, but it seems like it could be annoying.
    this.state.showCart = !this.state.showCart;
    TTC.cart = [];
    TTC.didChange();

    window.location = url;
  }
});

var ProductLine = React.createClass({
  render: function() {
    return (
      <tr>
        <th className="cart-table-sku"><a href="#" className="remove-item" onClick={this.remove}>&times;</a> {this.props.name}</th>
        <td className="cart-table-license">
          <div className="form-select-item">
            {this.renderLicenseSelect()}
          </div>
        </td>
        <td className="line-total">€ {this.props.price}</td>
      </tr>
    );
  },

  renderLicenseSelect: function() {
    return <select defaultValue={this.props.license} onChange={this.changeLicense}>
      {TTC.licenses.map(function(lic) {
        return <option key={lic.value} value={lic.value}>{lic.license}</option>
      }, this)}
    </select>;
  },

  remove: function(evt) {
    evt.preventDefault();
    this.props.onRemove();
  },

  changeLicense: function(evt) {
    this.props.onChangeLicense(evt.target.value);
  }
});

// (2) Purchase form
function setupPurchase(form) {
  var state = form.state = {};
  state.products = {};
  state.quantity = 1;
  state.prices = {all: 0};

  var fields = form.elements;
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (field.tagName == 'BUTTON') {
      field.textContent = 'Add to cart';
    }

    if (!field.name) continue;

    if (field.name.substring(0, 8) == 'product:') {
      var sku = field.name.substring(8);
      state.products[sku] = field.checked;

      if (sku != 'all') {
        var price = TTC.fontbysku[sku].fullPrice;
        state.prices[sku] = price;
        state.prices.all += price;
      }

    }

    field.onchange = function(evt) {
      var field = evt.target;
      var value = field.value;
      if (typeof field.checked == 'boolean' && field.name !== 'license-size')
        value = field.checked;
      updateState(form, field.name, value);
    }
  }

  updateState(form, 'product:all', state.products.all);

  form.onsubmit = function(evt) {
    evt.preventDefault();
    purchaseSubmit(form);
  }

  var style = document.createElement('style');
  style.textContent = '.js-purchase-show { display: block !important }';
  form.appendChild(style);
}

function updateState(form, name, value) {
  var state = form.state;

  // state.quantity = 1;
  if (name == 'product:all') {
    for (var key in state.products) {
      state.products[key] = value;
    }
  } else if (name.substring(0, 8) == 'product:') {
    var sku = name.substring(8);
    state.products[sku] = value;
  } else if (name == 'quantity' || name == 'license-size') {
    state.quantity = value;
  } else {
    throw new Error("Unknown field: " + name);
  }

  var selectedCount = 0;
  var count = 0;

  for (var sku in state.products) {
    if (sku == 'all') continue;
    count++;
    if (state.products[sku]) selectedCount++;
  }

  state.products.all = (selectedCount == count);
  state.selectedCount = selectedCount;

  renderPurchase(form);
}

function renderPurchase(form) {
  var state = form.state;
  var fields = form.elements;
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (field.tagName == 'BUTTON') {
      field.disabled = (state.selectedCount == 0);
    }

    if (!field.name) continue;

    if (field.name.substring(0, 8) == 'product:') {
      var sku = field.name.substring(8);
      field.checked = state.products[sku];
      // field.disabled = (state.quantity === 0)

      var label = field.parentElement;
      // label.parentElement.classList.toggle('disabled', field.disabled)

      var priceElem = label.querySelector('.js-font-price');
      if (priceElem) {
        priceElem.textContent = state.prices[sku] * (state.quantity || 1);
      }
    }

    if (field.name == 'quantity') {
      field.value = state.quantity;
    }
  }
}

// Handle the purchase submit button
function purchaseSubmit(form) {
  var state = form.state;
  var license = Number(state.quantity);
  var fields = form.elements;

  var lines = [];
  for (var key in state.products) {
    if (key == 'all') continue;
    if (state.products[key]) {
      lines.push({sku: key, license: license});
    }
  }

  // Now append the orders
  TTC.cart = TTC.cart.concat(lines);

  // Clear form
  state.quantity = '1';
  for (var key in state.products) {
    state.products[key] = false;
  }
  // Also clear the license value: just looping through the fields and setting it to Mini
  for (let f = 0; f < fields.length; f++) {
    if (fields[f].name == 'license-size' && fields[f].value != 1) { // if the value isn’t 1
      fields[f].checked = false;
    } else { // if the value is 1
      fields[f].checked = true;
    }
  }
  renderPurchase(form);

  // Trigger change
  TTC.didChange();

  // Update notification
  var notif = document.getElementById('js-shop-permanent');
  if (notif) {
    var c = notif.classList;

    c.remove('pop-in');
    c.add('pop-up');
    notif.removeAttribute('aria-hidden');
    setTimeout(function() {
      c.add('pop-in'); notif.setAttribute('aria-hidden', '');
    }, 3000);
  }
}

function parsePurchaseForm(form) {
  var fields = form.elements;

  var skus = [];
  var license;

  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (field.name == "license") {
      license = field.value;
    } else if (field.name == "sku") {
      if (field.checked !== false) {
        skus.push(field.value);
      }
    }
  }

  var orders = [];

  for (var i = 0; i < skus.length; i++) {
    orders.push({
      sku: skus[i],
      license: license
    });
  }

  return orders;
}
